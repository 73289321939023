import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import { authorPrem as author } from 'data/authors';
import Link from 'components/ui/link';

import cover from 'img/blog/session-replay-art/what-is-a-session-replay.png';
import debug from 'img/blog/session-replay-art/debug-commitstrip.jpg';
import userResearch from 'img/blog/session-replay-art/user-flow.png';
import rageClicks from 'img/livesessionApp/rageclicks.png';
import filters from 'img/livesessionApp/set_filters.png';
import styled from 'styled-components';

const Container = styled.div`
  h3,
  h2 {
    font-weight: 700;
    letter-spacing: 0.01em;
    margin: 2em 0 0.8em;
    line-height: 2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h2 {
    font-size: 1.5rem;
  }
`;

const Content = () => {
  return (
    <Container>
      <p>
        <strong>
          Session replay is a playback of a user’s journey on a website or within a web application
        </strong>
        . It shows what is happening on your site or app. The reconstruction consists of mouse and
        keyboard movements, scrolls, taps, and clicks. Gathered together, they show users’ paths on
        a site or app and let you feel as you were watching them live.{' '}
      </p>

      <p>
        Session replays are created by the use of session replay tools. Unlike most of the web
        analytics tools, they let you gather qualitative data that is crucial to understand how
        users experience a web or app interface.{' '}
        <strong>
          Having such insights, you can easily notice trends in the users’ behavior as well as find
          bugs and obstacles that impair customer experience.
        </strong>
      </p>

      <h2>Why do you need session replay software?</h2>

      <p>
        You already know what a session replay is, but you’re probably not sure whether you need it.
        Let me help you with that.{' '}
      </p>

      <p>
        You may know your app backwards and forwards. However, such knowledge won&apos;t tell you
        how users interact with its interface and what influences their online behavior.{' '}
      </p>

      <p>
        The success of your product or website depends not only on the fancy design, catchy texts or
        plethora of features, but also <strong>on the experience it delivers</strong>.
      </p>

      <p>And that’s where the complications begin.</p>

      <p>
        You can’t sit next to every client who uses your app or site to check how they behave,
        unless you have some time machine and the invisibility cloak, but let’s come down to Earth.{' '}
      </p>

      <img
        src={userResearch}
        className="img-fluid justify-content-center mb-4"
        alt="user-research"
        title="User research"
        loading="lazy"
      />

      <h3>The first option: manual user research</h3>

      <p>
        The first doable option that allows learning how users interact with the interface is to{' '}
        <strong>conduct user research</strong>. Simply, hire a professional who would sit with your
        clients, talk with them, observe what kind of actions they take, write everything down and
        analyze findings.
      </p>

      <p>
        Such research is very helpful and worth doing, but{' '}
        <strong>it might be very expensive and time-consuming</strong>.
      </p>

      <p>
        Additionally, user interviews are conducted with a designated group of people, meaning you
        must assume that the conclusion based on a definite group of users will correspond to the
        rest of your clients.
        <strong>This may not always be true.</strong>
      </p>

      <h3>Second option: use a session replay tool</h3>

      <p>
        You also have a second, less pricey and labor-intensive option; to use an above-mentioned
        session replay tool.
      </p>

      <p>
        Session replay solutions are useful for whole teams that work on a product; designers,
        developers, sales specialists or customer service agents.{' '}
      </p>

      <p>
        Like user research, they enable you to gather data about users but{' '}
        <strong>much cheaper and easier</strong>. You can use the gathered information to plan more
        accurate developments that bring visible changes and optimize the app or site experience.
        Finally, <strong>you can do it constantly</strong>, from iteration to iteration. With manual
        user research it wouldn’t be possible.
      </p>

      <h2>How does a session replay tool work?</h2>

      <p>
        To start recording data about users action on your website, first, you need to subscribe to
        a session replay tool. Of course, as a maker of LiveSession, I recommend our tool, but I
        hope you won’t blame me for that.
      </p>

      <p>
        Then you need to add a JavaScript tracking code to the <code>{'<head>'}</code> section of
        your site’s code. The rest is done by the app itself. As you see,{' '}
        <strong>it’s easy-peasy even for non-technical users</strong>.{' '}
      </p>

      <p>
        Thanks to the JavaScript code added to your website,{' '}
        <strong>the tool can monitor events in the user web browser</strong>. These are small
        actions that are taken by users on your site or app and involve the data transfer, like
        movements of the mouse. After data is collected, the tool sends it via AJAX requests to the
        server. Then, it’s indexed so that you get ready information about specific aspects right in
        your dashboard.
      </p>

      <h2>How can you benefit from using a session replay tool?</h2>

      <p>
        A session replay tool can work as your full-time UI researcher` who is capable to{' '}
        <strong>put all the findings into measurable data</strong>. Gathered knowledge can help you
        improve a lot of processes related to your site or app performance. As a result, you can
        cheaply and easily cover the following tasks:
      </p>

      <h3>1. Debug your website or app</h3>

      <p>
        Even if your frontend developers tried hard to avoid mistakes while coding your app or site,{' '}
        <strong>errors and bugs will happen and spoil users’ experience</strong>.
      </p>

      <p>
        What’s more, people who access your site may be using various devices or resolutions. Others
        may have a weak internet connection and might not be able to load your pages fast.{' '}
        <strong>
          The number of cases in which your website may break down delivering poor experience is
          infinite
        </strong>
        .
      </p>

      <div
        className="mb-4"
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          src={debug}
          className="img-fluid justify-content-center"
          alt="debug"
          loading="lazy"
          title="Debug your website or app"
        />
        <Link
          style={{ fontSize: '.9rem', color: '#6a7786' }}
          href="https://www.commitstrip.com/en/2015/03/23/brace-yourself-debug-is-coming/"
          target="_blank"
          rel="nofollow noreferrer noopener"
        >
          source: www.commitstrip.com
        </Link>
      </div>

      <p>
        In this case, a session replay tool comes in handy.{' '}
        <strong>
          Engineers can use it to find bugs much faster without spending ages on searching for
          sources of problems
        </strong>
        . Seeing the whole customer journey, they will know where the errors happen and easily
        define why they appear.{' '}
      </p>

      <p>
        In effect, your team will know what needs to be fixed to improve the customer experience,
        onboarding or the purchasing process. And most importantly, you will save a lot of money
        that would normally be spent on customer research and interviews.{' '}
        <strong>Resolving technical issues were never such easy to do.</strong>
      </p>

      <h3>2. Find out what frustrates your customers</h3>

      <p>
        Session replays are a great aid for UX designer. They allow capturing so-called{' '}
        <strong>error clicks</strong> that happen when a user can’t complete a specific task (load
        the page, move on to the next stage, finish onboarding, log in) and{' '}
        <strong>rage clicks</strong> that happen when a user furiously clicks a particular app
        element multiple times.
      </p>

      <div style={{ margin: '3rem 0' }}>
        <img
          src={rageClicks}
          className="img-fluid justify-content-center"
          alt="rage-clicks"
          loading="lazy"
          title="Rage clicks on website"
        />
      </div>

      <p>
        Finding which elements or steps in the process annoy your visitors will help to improve its
        design and introduce solutions that{' '}
        <strong>boost retention, prevent cart abandonment or churn</strong>.
      </p>

      <h3>3. Proactively help your customers</h3>

      <p>
        Knowing where and when the mistake happens is only half the battle, the second half is
        fixing it at the right time.
      </p>

      <p>
        By using a session replay tool, you can detect issues in real time. Find out who experiences
        a problem and immediately{' '}
        <strong>
          reach out to a troubled customer via chat, email or phone, and offer assistance
        </strong>
        .{' '}
      </p>

      <p>
        In a SaaS business, proactive customer service is a big asset. Modern customers can choose
        from plenty of solutions and brands delivering similar offers.{' '}
        <strong>Most of them expect a fast and smooth experience</strong>. And when they encounter
        problems, they get nervous. The part of them may try to contact you, but many of them will
        just look for another, easier, error-free app.
      </p>

      <p>
        Whereas thanks to session replays you don&apos;t have to passively wait for requests sent by
        frustrated customers. Your sales or customer success managers could use real time data reach
        out to users right when mistakes occur.{' '}
      </p>

      <p>
        What’s more, from a customer’s point of view your service will be smoother as they won’t
        need to send you screens or detailed explanations.{' '}
        <strong>
          You will know exactly what causes their problem and be able to deliver immediate help
          boosting clients satisfaction
        </strong>
        . This solution is possible thanks to{' '}
        <Link href="/help/user-identification-custom-properties/" target="_blank">
          user identification in session replays
        </Link>{' '}
        and integrations with live chats.
      </p>

      <h3>4. Find out what interests your users</h3>

      <p>
        Wouldn&apos;t it be great to get feedback from your users without even asking a question?
      </p>

      <p>
        <strong>Session replay tools let you do so</strong>. Product managers and marketers can use
        them to check which of app features, texts or interface elements attract users and support
        conversion. Knowing which sections enjoy the biggest popularity will help you find out which
        course to follow.
      </p>

      <h3>5. Segmentize your customers</h3>

      <p>
        By recording your visitors’ paths on your website you can also group them based on specific
        characteristics, like source that they came from, the type of services they use, browsers,
        elements they clicked on or even <strong>parameters defined by you</strong>.
      </p>

      <div style={{ margin: '3rem 0' }}>
        <img
          src={filters}
          className="img-fluid justify-content-center"
          alt="filters"
          style={{
            border: 'none',
            borderRadius: '4px',
            boxShadow: 'rgba(39,44,49,0.08) 8px 14px 38px, rgba(39,44,49,0.08) 1px 3px 8px',
          }}
          loading="lazy"
          title="Segmentize your customers"
        />
      </div>

      <p>
        Being aware of what your customers’ profiles are, you can optimize the experience to their
        needs and preferences.
      </p>

      <p>
        You can find out whether any of your site’s versions or mobile apps provide a disparate
        experience than expected and needs improvement.
      </p>

      <h3>6. Boost conversion rates</h3>

      <p>
        Probably you use some web analytics tool (like Google Analytics) and gather data from your
        checkout pages. Thanks to it, you know what your conversion rate is. But it wouldn’t hurt if
        your sales went up a bit, right?
      </p>

      <p>
        A <strong>session replay tool may also help you sell more</strong>. Using it you can check
        whether there are any elements that confuse users or obstacles that prevent them from
        completing particular tasks. You may learn whether your onboarding process is clear or your
        Call-To-Actions are created properly.{' '}
      </p>

      <p>
        By watching user playbacks you can check whether introduced solutions work as assumed. You
        can base your decisions on data, not assumptions and focus on changing only the elements
        that actually need improving.
      </p>

      <h3>7. Extend the context of other analytical tools</h3>

      <p>The more you know about your customers, the better experience you can deliver. </p>

      <p>
        By integrating a session replay tool, which provides qualitative data, with solutions that
        collects quantitative data, you can get a much broader view on your customers and their
        behavior.
      </p>
      <p>
        <strong>Example of such integration</strong>:{' '}
        <Link href="/help/google-analytics-integration/" target="_blank">
          the integration between LiveSession and Google Analytics
        </Link>{' '}
        allows you to send links to session playbacks from to the GA tracker. In effect, you can
        open recordings of a specific user directly from the GA dashboard and obtain information
        that would be hard to discover without integrating the tools.{' '}
      </p>

      <p>
        As you can see, session replays not only allow you to reproduce users’ behavior but also
        uncover tons of qualitative data about your site and app. Together with information gathered
        via other web analytics tools, they let you get the broad spectrum of knowledge that helps
        to get rid of bugs, provide better customer service and optimize user experience. And the
        best part is that{' '}
        <strong>
          you can complete all these tasks without hiring teams of specialists who wouldn’t be able
          to capture such data anyway
        </strong>
        .{' '}
      </p>
    </Container>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'What is session replay?',
  url: '/blog/what-is-session-replay',
  description: 'Check out how session replay can help your business grow.',
  author,
  img: cover,
  date: '2019-03-07',
  category: '',
  imgStyle: { borderBottom: '1px solid rgba(0,0,0,0.125)' },
  group: 'Articles',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    descriptionSocial="Check what is session replay and how can it help your business grow."
    img={frontmatter.img}
  />
);
